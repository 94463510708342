.table-container {
    width: 100%;
    overflow-x: auto; /* Allows horizontal scroll if needed on smaller devices */
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Table Head (Desktop) */
  .responsive-table thead {
    background-color: #f2f2f2;
  }
  
  .responsive-table th,
  .responsive-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    /* Hide table header on small screens */
    .responsive-table thead {
      display: none;
    }
  
    /* Force rows and cells to be displayed as blocks */
    .responsive-table,
    .responsive-table tbody,
    .responsive-table tr,
    .responsive-table td {
      display: block;
      width: 100%;
    }
  
    .responsive-table tr {
      margin-bottom: 15px; /* space between rows */
    }
  
    .responsive-table td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
  
    /* Create a "label" in each cell using data-label */
    .responsive-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  .truncated-cell {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* show 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em; /* ~1.5em per line if your line-height is 1.5 */
  }
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .responsive-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .responsive-table th,
  .responsive-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {

    
    .responsive-table thead {
      display: none;
    }
    .responsive-table, 
    .responsive-table tbody, 
    .responsive-table tr, 
    .responsive-table td {
      display: block;
      width: 100%;
    }
    .responsive-table tr {
      margin-bottom: 15px;
    }
    .responsive-table td {
      text-align: left;
      /* padding-left: 50%; */
      position: relative;
    }
    .responsive-table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  